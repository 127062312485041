import * as React from 'react';

import HeroImg from '../../../../src/images/__new/read_more/img1.jpg';

const HeaderContent = (props) => {
  return (
    <div className={'headerContent storyworldHeaderContent'}>
      <div className={'heroImage'}>
        <img src={HeroImg} alt={'Saxophonist'} />
      </div>
      <h1 className='title'>
        <span className='whiteText'>YOU MUST NEVER DOUBT YOUR ABILITY TO </span>
        ACHIEVE ANYTHING BECOME ANYTRHING OVERCOME&nbsp;ANYTHING
        AND&nbsp;INSPIRE EVERYTHING.
      </h1>
    </div>
  );
};

export default HeaderContent;
