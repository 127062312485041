import * as React from 'react';

import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';

import Monument from '../../../images/__new/storyworld/img8.jpg';
import BgImage from '../../../images/__new/storyworld/img8_bg.jpg';

const FifthSection = (props) => {
  return (
    <section className={'storyworldFifithSection'}>
      <div className='bgImage'>
        <img src={BgImage} alt='bgImage' role='presentation' loading='lazy' />
      </div>
      <Layout>
        <div className='sectionDescription'>
          <p className='text'>
            This powerful, new music soon reverberates all the way to Germany
            and likewise stimulates people to dance there, sending them into a
            thunderous frenzy and often euphoria. Here, jazz becomes the
            soundtrack of the Golden Twenties. Unfortunately, however, the Nazis
            soon put an end to this. The end of the dance. Jazz is banned from
            Germany as a degenerated style of music.
          </p>
          <p className='text'>
            Almost simultaneously — not far away — the saxophone is banned in
            the Soviet Union as well. The communist country completely disposes
            of and forbids all music notes to the compositions during the purge.
            Every single person who owns this imperialist instrument has to give
            it up.
          </p>
          <p className='text'>ein Bass-Saxophon aus Messing.</p>
          <p className='text'>
            Today you can find the saxophone everywhere from concert halls to
            the smoky jazz bars all over the world but not in the classical
            concert halls. The saxophone is virtually non-existent in classical
            music. It is generally ruled out from the start that the saxophone
            could be an integral part of orchestral music or could even be used
            as a soloist instrument. The instrument however features amazing
            flexibility — every musician can develop his or her own individual
            sound.
          </p>
        </div>
      </Layout>
      <LeftRight className={'firstLeftRightSection'}>
        <Layout>
          <Left className=''>
            <div className='imageContainer'>
              <img
                src={Monument}
                alt='Saxophonist'
                className='image'
                loading='lazy'
              />
            </div>
          </Left>
          <Right>
            <div className='sectionDescription'>
              <p className='text'>
                We are certain: if Beethoven were alive today, he would be the
                greatest fan of the saxophone. Beethoven was anything but
                elitist, he was a musical visionary.
              </p>
              <p className='text'>
                Instead, the sax becomes all the more popular among black
                musicians in New Orleans in the 1920s. At that time, the sax was
                not only an instrument for these musicians, it was a mouthpiece
                with a powerful voice that allowed them to express themselves in
                the most ideal way. So, this is how two outsiders find each
                other: the oppressed black minority, finding potent expression
                through this outlawed instrument.
              </p>

              <p className='text'>
                He would have incorporated the saxophone into his symphonies and
                musically demonstrated to his audience what this "seductive
                instrument" was capable of.
              </p>
              <p className='text'>
                The saxophone and its many possibilities connects and influences
                musicians all over the world to push their musical boundaries,
                to experiment creatively and to develop new styles — all this
                would be good for the dusty classical environment in our modern
                world.
              </p>
              <p className='text'>
                We have often asked ourselves the question: what would the
                modern world of classical music sound like today if it opened
                its doors to one of the most versatile instruments in the world?
                Would the saxophone be able to free classical music from its
                rigidity and thus perhaps even save it from its crisis?
              </p>
              <p className='text'>
                Does the saxophone have the power to break musical boundaries?
                That is what we at GRADIVARI want to believe — just like its
                inventor Adolphe Sax.
              </p>
            </div>
          </Right>
        </Layout>
      </LeftRight>
    </section>
  );
};

export default FifthSection;
