import * as React from 'react';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';

import FirstImage from '../../../images/__new/storyworld/img4.jpg';
import SaxophoneImage from '../../../images/__new/storyworld/img5.jpg';
import SaxophoneMural from '../../../images/__new/storyworld/img6.jpg';

const ThirdSection = (props) => {
  return (
    <div className='storyworldThirdSection'>
      <Layout>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            <span className='yellowText'>ADOLPHE SAX</span>
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <p className='text'>
            After modernizing the clarinet in 1835, he intends to construct a
            completely new instrument. His vision: „...ein Instrument zu
            erscha#en, das im Charakter seiner Stimme den Streichinstrumenten
            nahe- kommt, aber mehr Kraft und Intensität besitzt als diese." So
            hat Sax zufällig auch ein Instrument erfunden, dass auch der
            menschlichen Stimme am nächsten kommt.
          </p>
          <p className='text'>
            Without a penny in his pockets, the young man is drawn to Paris —
            his companion: the completely new instrument he invented. And he
            calls it: „un saxophone basse en cuivre", the bass saxophone from
            brass.
          </p>
          <p className='text'>ein Bass-Saxophon aus Messing.</p>
          <p className='text'>
            The young Belgian and his completely new instrument soon attract the
            attention of the Parisian musical community. Musicians and composers
            like Hector Berlioz are immediately taken by this young, visionary
            man and his instrument. Adolphe Sax and his Instrument become
            famous. Parisian musicians love him, meanwhile his competitors,
            however, hate him.
          </p>
          <div className='imageContainer'>
            <img
              src={FirstImage}
              alt='Saxophonist'
              className='image'
              loading='lazy'
            />
          </div>
        </div>
      </Layout>
      <LeftRight className={'firstLeftRightSection'}>
        <Layout>
          <Left>
            <div className='sectionDescription'>
              <p className='text'>
                They try to destroy the young Belgian. His French rivals do
                everything to prevent his further success. They plot against
                him. They try to spread slander about him in false press
                releases. They try to physically intimidate him. And when none
                of this works and young Adolphe refuses to give in, his studios
                are set on fire.
              </p>
              <p className='text'>
                Even this act of outright sabotage cannot stop Adolphe Sax. The
                man Sax and his SAX become stars. Nevertheless: SAX hardly plays
                a role in classical and folk music. Sax dies impoverished in
                1894. But his SAX lives on and continues his triumphal
                procession through the music world. Where it also quickly stirs
                up emotions in those who are irritated rather than enthusiastic
                about this instrument, with its "seductive and vulgar
                sounds"....
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img
                src={SaxophoneImage}
                alt='Saxophonist'
                className='image'
                loading='lazy'
              />
            </div>
          </Right>
        </Layout>
      </LeftRight>
      <LeftRight className={'secondLeftRightSection'}>
        <Layout>
          <Left className=''>
            <div className='imageContainer'>
              <img
                src={SaxophoneMural}
                alt='Saxophonist'
                className='image'
                loading='lazy'
              />
            </div>
          </Left>
          <Right>
            <div className='sectionDescription'>
              <p className='text'>
                In 1914, the "seductive" sound becomes too "hot" for the church.
                Furthermore, an instrument with a name that also sounds like
                something "dirty"? That can only be the instrument of the devil
                who’s seeking to seduce decent people through this evil musical
                contraption. No Way!, the Vatican shouts angrily and bans the
                SAX in all churches and services in that very same year. From
                now on, there will be no more sounds of seduction in the house
                of God.
              </p>
              <p className='text'>
                Instead, the sax becomes all the more popular among black
                musicians in New Orleans in the 1920s. At that time, the sax was
                not only an instrument for these musicians, it was a mouthpiece
                with a powerful voice that allowed them to express themselves in
                the most ideal way. So, this is how two outsiders find each
                other: the oppressed black minority, finding potent expression
                through this outlawed instrument.
              </p>

              <p className='text'>
                These are the best conditions for a completely new style of
                music. A style of music that not only moves people, but also
                frees them from their own as well as social constraints. The
                saxophone becomes the weapon in the fight against oppression and
                jazz becomes the soundtrack of their own liberation. Not only
                for blacks, but also for artists, poets and dissidents from all
                over the world.
              </p>
            </div>
          </Right>
        </Layout>
      </LeftRight>
    </div>
  );
};

export default ThirdSection;
