import * as React from 'react';

import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import SaxophonistImage1 from '../../../images/__new/storyworld/img2.jpg';

const FirstSection = (props) => {
  return (
    <div className={'storyworldFirstSection'}>
      <LeftRight>
        <Layout>
          <Left className='leftstoryworldContainer'>
            {' '}
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                IN THE BEGINNING,{' '}
                <span className='yellowText'>
                  THERE&nbsp;WAS THE&nbsp;WORD?
                </span>
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='text'>
                Nope, in the beginning, there was music — the melody, the
                rhythm. Melodies and rhythms have always fascinated us humans.
              </p>
              <p className='text'>
                The first bone flutes were found over 30,000 years ago. We
                humans made music before we developed language. Music has always
                been and still is today: communication without words. This
                separation of musicians and passive listeners is a new
                development. Throughout much of human history, music has usually
                been a communal, often festive experience associated with dance.
                Researchers are of the opinion that music may have strengthened
                the sense of togetherness of tribal groups early on.
              </p>

              <p className='text'>
                How do musical ideas emerge that touch people all over the world
                and become immortal? What sounds, tones and vibrations do pieces
                of music consist of that captivate their audience? How do
                musical goosebump moments arise? How do musicians manage to
                create music that sounds so simple and yet is so deep, so rich
                and every note tells a new story?
              </p>
              <p className='text'>
                We humans will probably never be able to fully understand and
                clarify the power and magic of music, but we can try to make
                music as intensely accessible to us humans as possible.
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img src={SaxophonistImage1} alt='Saxophonist' loading='lazy' />
            </div>
          </Right>
        </Layout>
      </LeftRight>
      <div className='textSection'>
        <Layout>
          <div className='sectionTitle'>
            <h2 className='sectionTitleText'>
              FROM THE INSTRUMENT OF THE DEVIL{' '}
              <span className='yellowText'>TO THE VOICE OF LIBERATION</span>
            </h2>
          </div>
          <HorizontalSectionLine />
          <div className='sectionDescription'>
            <p className='text'>
              The saxophone is one of the most popular instruments in the world.
              Nevertheless, it is still hardly recognized in the classical music
              world.
            </p>
            <p className='text'>
              Why? Is it because the classical world is unfortunately still too
              elitist or is it because of the saxophone? Probably the history of
              the saxophone plays an important role. The one who holds it in his
              or her hands and plays it, will love it. This has always been the
              case — but the saxophone has never had it easy.
            </p>
            <p className='text textBold'>
              A brief look into the eventful past of SAX:
            </p>
            <p className='text lastParagraph'>
              Around 1830, a 15-year-old boy in Belgium — named Adolphe Sax —
              indulges in his passion for wind instruments. The results are new,
              interesting flute and clarinet models. He continues to experiment
              and also adopts the clarinet, which originates from Germany.
            </p>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default FirstSection;
