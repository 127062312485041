import React from 'react';

import '../scss/main.scss';

import Seo from '../components/inc/seo';

import Header from '../components/header';
import HeaderContent from '../components/storyworld/headerContent';
import FirstSection from '../components/storyworld/firstSection';
import SecondSection from '../components/storyworld/secondSection';
import ThidrSection from '../components/storyworld/thirdSection';
import FourthSection from '../components/storyworld/fourthSection';
import FifthSection from '../components/storyworld/fifthSection';
import SixthSection from '../components/storyworld/sixthSection';
import TopBtn from '../components/inc/topBtn';

import Footer from '../components/footer';

const OurStoryPage = () => {
  return (
    <>
      <Seo />
      <Header>
        <HeaderContent />
      </Header>
      <main>
        <FirstSection />
        <SecondSection />
        <ThidrSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
      </main>
      <Footer />
      <TopBtn />
    </>
  );
};

export default OurStoryPage;
