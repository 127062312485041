import * as React from 'react';

import Layout from '../../layout';

import SectionBg1 from '../../../images/__new/storyworld/img7.jpg';

const FourthSection = (props) => {
  return (
    <div className={'storyworldFourthSection'}>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg1}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              NEVER LIMIT
              <br />
              YOUR ABILITY.
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default FourthSection;
