import * as React from 'react';

import Layout from '../../layout';

import SectionBg1 from '../../../images/__new/storyworld/img3.jpg';

const FirstSection = (props) => {
  return (
    <div className={'storyworldSecondSection'}>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg1}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>LIMITLESS*</p>
            <p className='underTitleText'>
              *An adjective that means magic happens to those who never stop
              playing.
            </p>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default FirstSection;
